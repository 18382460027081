/* Global Styles */
h1 {
    font-size: clamp(2.5rem, 6vw, 4.375rem);
}

h2, h3, h4 {
    color: var(--pink);
    font-weight: 600;
    text-align: center;
    background-color: transparent;
}

h2 {
    font-size: clamp(2rem, 5vw, 3.125rem);
}

main.p {
    padding-bottom: 2rem;
}

strong {
    font-weight: 700;
    color: var(--pink);
}

/* Custom Classes */
.rounded {
    border-radius: 20%;
}

.pfp-dance {
    height: auto;
    cursor: pointer;
    transition: transform 0.6s ease-out;
}

.split .pfp-dance:hover {
    animation: wave 0.8s ease-in-out infinite alternate, wave-zoom 1.2s ease-in-out infinite alternate;
    cursor: none;
}

.border {
    border: 3px solid red;
}

.fit-content {
    height: fit-content;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    height: fit-content;
}

/* Flex Layout */
.split {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    text-align: left;
}

.split-1 {
    flex: 1;
}

.split > img {
    max-width: 35%;
    flex-shrink: 0;
    margin: 0;
}

/* Slider Styling */
.slider-slide {
    display: none;
    margin: 0 auto;
    width: 90%;
    border-radius: 15px;
    color: #fff;
    padding: 1rem;
}

/* Buttons */
.w3-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: clamp(2rem, 2vw, 1.125rem);
    padding: clamp(0.5rem, 1vw, 0.8rem) clamp(0.8rem, 2vw, 1rem);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.w3-left {
    left: 5%;
}

.w3-right {
    right: 5%;
}

.w3-button:hover {
    background: rgba(255, 255, 255, 0.5);
}

/* IDs */
#intro {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
}

#intro h1 {
    color: var(--pink);
    margin-bottom: 10px;
}

#intro p {
    color: #fff;
    max-width: 500px;
    font-size: clamp(1.875rem, 0.5vw, 1.2rem);
    text-align: center;
}

#personality {
    position: relative;
    text-align: center;
    margin: 1% auto;
    max-width: 1400px;
}

#personalityText {
    background-color: #1a1a1a;
    border-radius: 15px;
}

.slider-slide .text {
    padding: 20px;
    margin-bottom: 20px;
    font-size: clamp(1.895rem, 2.5vw, 1rem);
    line-height: 1.6;
    text-align: justify;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .split {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .split > img {
        max-width: 90%;
    }

    #intro p {
        max-width: 90%;
    }

    .slider-slide {
        width: 95%;
    }

    .w3-button {
        font-size: 0.875rem;
        padding: 0.6rem 0.8rem;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .split > img {
        max-width: 80%;
    }



    .slider-slide .text {
        font-size: 0.95rem;
    }

    .w3-button {
        font-size: 0.75rem;
        padding: 0.5rem 0.6rem;
    }
}
