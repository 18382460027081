/* Header */
header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #f0ceea;
    width: 80%;
    margin: 0 auto;
}

/* Border Section */
header #border {
    width: 100%;
    border-bottom: 1px solid var(--pink);
    padding-bottom: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Navbar Left Section */
header .navbar_left {
    cursor: pointer;
    margin-right: auto;
}

/* Profile Picture */
.img-round {
    border-radius: 50%;
    width: 70px;
}

/* Navbar Links */
.nav__links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.nav__links li {
    display: inline-block;
    margin: 0 15px;
    position: relative;
}

.nav__links li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.nav__links li a:hover {
    color: var(--pink);
    font-weight: 550;
}

/* Effects Switch Container */
.effects-container {
    position: relative; /* Needed for the tooltip positioning */
    display: flex;
    align-items: center;
    gap: 10px; /* Space between text and switch */
}

/* Tooltip Text */
.effects-container .tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: -65px; /* Position the tooltip below the container */
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease;
    z-index: 1000;
}

/* Tooltip Arrow */
.effects-container .tooltip::after {
    content: '';
    position: absolute;
    top: -15px; /* Position the arrow above the tooltip */
    left: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}

/* Show Tooltip on Hover */
.effects-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateY(-5px); /* Slightly animate it upwards */
}

/* Label Text */
.effects-container p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #f0ceea;
}

/* Switch Wrapper */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

/* Slider Background */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.3s ease;
}

/* Slider Handle */
.slider:before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

/* Checked State */
input:checked + .slider {
    background-color: var(--pink);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

/* Media Queries for Responsiveness */
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    /* Adjust the border section for mobile */
    header {
        width: 90%;
    }

    header #border {
        flex-direction: row; /* Keep items in a row */
        align-items: center;
        justify-content: space-between; /* Space out items */
    }

    .nav__links {
        display: flex;
        flex-direction: row; /* Display items in a row */
        gap: 10px;
    }

    /* Hide "About" and "Gallery" links */
    .nav__links li:nth-child(2), /* About */
    .nav__links li:nth-child(3) /* Gallery */ {
        display: none;
    }

    /* Adjust Effects container for alignment */
    .effects-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px; /* Reduce spacing for mobile */
    }

    /* Tooltip adjustments for mobile */
    .effects-container .tooltip {
        bottom: -45px; /* Adjust position for smaller screens */
        font-size: 12px; /* Smaller font for tooltip */
    }
}
