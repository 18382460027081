@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

:root {
    --pink: #f0ceea;
    --pink-new: #ff66b2;
    --text: #f6eef4;
    --background: #0b0011; /* Blackish-purple */
    --background2: #200035; /* Dark purple */
    --star-color: #ffffff; /* Star color */
    --star-glow: rgba(255, 255, 255, 0.5); /* Star glow */
}

/* GENERAL RESET */
* {

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.custom-cursor button:hover  {
    cursor: url('https://img.icons8.com/?size=32&id=JZpgit5EnSxl&format=png'), auto;
}


.round {
    border-radius: 20% !important;
}



html {
    scroll-behavior: smooth;
    min-height: 100%;
}

body {
    margin: 0;
    font-family: 'Roboto Mono';
    background: linear-gradient(180deg, var(--background), var(--background2));
    overflow-x: hidden; 
    position: relative;
    min-height: 100%;
    color: var(--text);
}

.custom-cursor {
    cursor: none !important; /* Hide the default cursor */
    position: fixed;
    width: 32px;
    height: 32px;
    background: url('https://img.icons8.com/?size=32&id=JZpgit5EnSxl&format=png') no-repeat center center;
    background-size: contain;
    pointer-events: none; /* Allow clicks to pass through */
    transform: translate(-50%, -50%) rotate(0deg); /* Center and allow rotation */
    z-index: 9999; /* Ensure it stays on top of everything */
    transition: transform 0.1s linear; /* Smooth rotation transition */
}


section {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text);
}

.sectionHeight {
    height: 100vh;

}



/* STARFIELD CONTAINER */
.stars-container {
    position: fixed; /* Ensure stars stay fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    pointer-events: none; /* Allow clicks to pass through */
}

/* INDIVIDUAL STAR STYLES */
.star {
    position: absolute;
    width: 2px;
    height: 2px;
    background: var(--star-color);
    border-radius: 50%;
    box-shadow: 0 0 10px var(--star-glow);
    animation: twinkle 5s infinite ease-in-out, move-stars linear infinite;
}

/* TWINKLE ANIMATION */
@keyframes twinkle {
    0%, 100% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
}

/* STAR MOVEMENT */
@keyframes move-stars {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh);
    }
}
