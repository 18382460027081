/* Global Styles */
h1 {
    font-size: 50px !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    letter-spacing: 2px !important;
    transition: color 0.3s !important;
}

h1:hover {
    color: #ff99cc;
}

#about-me {
    width: 80%;
    margin: 0 auto;
    padding: 100px 0px 400px 0;
    font-size: clamp(2.2rem, 2vw, 1.125rem);
}

/* Games Section */
.games {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: linear-gradient(120deg, #1b1b1b, #242424);
    color: #f1f1f1;
    padding: 40px 20px;
}

/* Cards Section */
.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: center;
    padding: 0 0 50px 0;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    background: #333;
    color: #eceff1;
    max-width: 430px;
    width: 100% !important;
    padding: 20px;
    position: relative;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
}

.card img {
    object-fit: contain;
    border-radius: 10px;
    margin-top: 15px;
    transition: transform 0.4s ease;
    border: 2px solid #ff66b2;
    padding: 5px;
}

.card img:hover {
    transform: rotate(360deg) scale(1.1);
    transition: transform 1s ease;
}

.card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    text-align: justify;
    width: 100%;
    margin-bottom: 60px;
}

.cards-title {
    padding: 200px 0 50px 0;
}

.cards h2 {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 700;
    color: #ff66b2;
    transition: color 0.3s;
}

.cards h2:hover {
    color: #ff99cc;
}

.cards p {
    font-size: 16px;
    line-height: 1.6;
    color: #b0bec5;
    padding-bottom: 30px;
}

.placeholder {
    display: none;
    font-size: 14px;
    color: #b0bec5;
    text-align: center;
}

.card img[onerror] + .placeholder {
    display: block;
}

.playtimeAll {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

/* Button Styles */
.button {
    display: inline-block;
    padding: 12px 25px;
    margin: 25px 0;
    color: #fff;
    background-color: #ff66b2;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 1px;
    transition: background-color 0.3s, transform 0.3s;
}

.button:hover {
    background-color: #ff99cc;
    transform: translateY(-5px);
}

/* Stats Section */
.stats-stats {
    width: 100%;
    max-width: 83.2%;
    margin: 40px auto;
    padding: 40px;
    background-color: #1b1b1b;
    color: #f1f1f1;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

.stats-stats h2 {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    font-family: 'Roboto Mono', monospace;
    text-transform: uppercase;
    color: #ff66b2;
    margin-bottom: 20px;
}

.stat-item {
    margin-bottom: 20px;
    background-color: #242424;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
}

.stat-info {
    display: grid;
    grid-template-columns: 70px auto 100px 110px;
    align-items: center;
    gap: 15px;
    margin: 0;
}

.label {
    font-weight: 600;
    color: rgb(255, 36, 200);
    font-size: 18px;
}

.progress-bar-wrapper {
    width: 100%;
    background-color: #333;
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    height: 100%;
    background-color: rgb(255, 36, 200);
    transition: width 0.4s ease;
}

.time {
    font-size: 14px;
    color: #b0bec5;
    text-align: right;
    white-space: nowrap;
}

.projects-toggle {
    padding: 8px 15px;
    font-size: 14px;
    color: #fff;
    background-color: rgb(255, 36, 200);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.projects-toggle:hover {
    background-color: #ff99cc;
}

.projects-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    margin-top: 0;
    padding-left: 20px;
    background-color: #2d2d2d;
    border-radius: 10px;
}

.projects-content.open {
    max-height: 500px;
    opacity: 1;
    padding: 10px 100px;
}

.projects-content ul {
    margin: 15px 0;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1;
}

.projects-content ul:first-child {
    margin-top: 25px;
}

.projects-content ul:last-child {
    margin-bottom: 25px;
}

.projects-content li {
    font-size: 14px;
    line-height: 1.6;
    color: #b0bec5;
}

.projects-content ul .project:hover {
    color: #ff99cc;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    section {
        padding: 20px;
    }

    .games h1 {
        font-size: 45px;
    }

    .card {
        height: 60vh;
        max-width: 100%;
    }

    .cards h2 {
        font-size: 30px;
    }

    .cards p {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .games h1 {
        font-size: 40px;
    }

    .card {
        height: 80vh;
        max-width: 100%;
    }

    .cards h2 {
        font-size: 22px;
    }

    .cards p {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .games h1 {
        font-size: 32px;
    }

    .card {
        height: 60vh;
        max-width: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .card-content {
        padding: 15px;
    }

    .cards p {
        font-size: 13px;
    }
}

@media (max-width: 850px) {
    .stat-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .label,
    .time,
    .projects-toggle {
        width: 100%;
        text-align: left;
    }

    .progress-bar-wrapper {
        width: 100%;
    }

    .projects-toggle {
        align-self: flex-start;
    }
}
