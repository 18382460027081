
@keyframes background-color {
    from {
        background-position: 0% center;
    }
    to {
        background-position: -200% center ;
    }
}

@keyframes wave {
    0% {
        transform: scale(1.05) rotate(2deg);
    }
    100% {
        transform: scale(1.05) rotate(-2deg);
    }
}


@keyframes wave-zoom {
    0% {
        transform: scale(1) rotate(2deg); 
    }
    50% {
        transform: scale(1.15) rotate(-2deg); 
    }
    100% {
        transform: scale(1) rotate(2deg);
    }

}