h1 {
    color: #ff66b2;
}

p.important {
    color: pink;
    font-weight: 500;
}

p.bold {
    font-weight: bold;
    text-decoration: underline;
}

p.bold:hover {
    color: #ff66b2;
    font-weight: bold;
    text-decoration: underline;
}