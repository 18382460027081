footer {
    color: #fff;
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0px 20px;
}

.footer-columns {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    align-items: flex-start; 
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    gap: 20px; 
}

.footer-section {
    flex: 1; 
    min-width: 150px; 
    padding: 0 20px; 
    text-align: center; 
}

.footer-bottom {
    font-size: 12px;
    color: #888;
    padding: 10px 0 0px 0;
    text-align: center;
    width: 100%;
    border-top: 1px solid #333; 
}

footer ul  {
    padding: 0;
    margin: 10px 0; 
    list-style: none;
}

footer li {
    color: #888;
    margin-bottom: 8px; 
}

footer a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

a:hover {
    color: #f0ceea;
    text-decoration: underline;
}

h3 {
    color: #f0ceea; 
    margin-bottom: 10px; 
    font-size: 18px; 
}

a.secret {
    color: #888;
    font-size: 10px;
    text-decoration: none;
    opacity: 0; 
    transition: opacity 0.3s ease;
    padding: 0px;
    margin: 0px;
}

p {
    color: #888;
    margin-bottom: 0;
}

a.secret:hover {
    color: #f0ceea;
    font-weight: 500;
    text-decoration: underline;
    opacity: 1; 
}
